import { ActionUser } from "../actions/user";
import { ActionTypeUser } from "../action-types";

// Initial state is an empty object
// "Authentication" logic will check if this object is empty, for a logged out user, or has contents and is therefore a
// logged in user.
const initialState = {
  currentUser: {},
  isLoggedIn: false,
};

/**
 * Auth reducer
 *
 * What does a reducer do?
 * A reducer receives the current redux state as well as an action object. The action is dispatched and the reducer acts
 * upon it before returning a new redux state object. In other words, the reducer is "listening" for a specific action.
 * When that action is dispatched, redux takes that information, or payload, modifies it if necessary, and returns it in
 * a new object. It's important to note that redux state should be treated as immutable, so always return a new object.
 *
 * @param state
 * @param action
 */
const authReducer = (state: object = initialState, action: ActionUser) => {
  switch (action.type) {
    case ActionTypeUser.USER_LOGIN:
      return {
        ...state,
        currentUser: action.payload,
        isLoggedIn: true,
      };
    case ActionTypeUser.USER_LOGOUT:
      return {
        ...state,
        currentUser: {},
        isLoggedIn: false,
      };
    case ActionTypeUser.USER_UPDATE:
      return {
        ...state,
        currentUser: action.payload,
      };
    case ActionTypeUser.USER_LOGIN_FAILURE:
      return {
        ...state,
        loginError: {
          active: true,
          message: action.payload,
        },
      };
    case ActionTypeUser.USER_LOGIN_CLEAR_ERROR:
      return {
        ...state,
        loginError: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export default authReducer;
