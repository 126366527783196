import Anchor from "../../fields/anchor/Anchor";

const Logo = () => {
  return (
    <div className="logo relative">
      <div className="logo-content">
        <Anchor href="/">
          <div className="tag">An API for</div>
          <div className="logo-name text-gradient glow-tertiary">SPACE-X</div>
          <div className="motto">A DIGITAL EXPERIENCE</div>
        </Anchor>
      </div>
      <div className="triangle-bg">
        <div className="triangle glow-primary">▲</div>
        <div className="lines"></div>
      </div>
    </div>
  );
};

export default Logo;
