import React, { useEffect, useState } from "react";
import IPage from "../../../interfaces/page";
import { RouteComponentProps } from "react-router-dom";
import {
  getDateOutput,
  undoUrlSafe,
} from "../../../helperFunctions/helper-functions";
import spaceXQuery from "../../../api/spacex";

const LaunchPage: React.FC<IPage & RouteComponentProps<any>> = (props) => {
  const pathname = window.location.pathname;
  const launchName = pathname.split("/")[2];
  const [launch, setLaunch] = useState<any>({});

  useEffect(() => {
    // If no launchName, don't do anything
    if (!launchName) {
      return;
    }

    const options = {
      endpoint: "launches",
      query: {
        name: {
          $eq: undoUrlSafe(launchName),
        },
      },
      options: {
        select: {
          date_utc: 1,
          flight_number: 1,
          id: 1,
          links: 1,
          name: 1,
          success: 1,
        },
      },
    };

    const getData = async () => {
      const launchData = await spaceXQuery(options);
      if (launchData) setLaunch(launchData.docs[0]);
    };

    getData().then();
  }, [props, launchName]);

  const date = new Date(launch?.date_utc);
  return (
    <div className="p-4">
      <h1 data-testid="page-title" className="text-2xl mb-4 text-secondary">
        Launch{launch?.name ? `: ${launch.name}` : null}
      </h1>

      <table className="table-fixed">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Success</th>
            <th>Flight No.</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-1/6 px-2 text-center">{launch?.name}</td>
            <td className="w-1/6 px-2 text-center">
              {launch?.date_utc ? getDateOutput(date) : null}
            </td>
            <td className="w-1/6 px-2 text-center">
              {launch?.success ? "True" : "False"}
            </td>
            <td className="w-1/6 px-2 text-center">{launch?.flight_number}</td>
            <td className="w-1/6 px-2 text-center">
              {launch?.links?.wikipedia ? (
                <a
                  href={launch?.links?.wikipedia}
                  target="_blank"
                  rel="noreferrer"
                >
                  More Info
                </a>
              ) : null}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LaunchPage;
