import { ActionLoading } from "../actions/loading";
import { ActionTypeLoading } from "../action-types";

// Initial state is an empty object
const initialState = {
  isLoading: false,
};

/**
 * Loading reducer
 *
 * This could have easily been tacked onto an existing reducer, but let's keep adequate separation of concerns
 *
 * @param state
 * @param action
 */
const loadingReducer = (
  state: object = initialState,
  action: ActionLoading
) => {
  switch (action.type) {
    case ActionTypeLoading.LOADING_ACTIVE:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypeLoading.LOADING_INACTIVE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default loadingReducer;
