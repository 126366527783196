import React, { useState } from "react";
import Anchor from "../../fields/anchor/Anchor";

const NavMain = () => {
  const [burger, setBurger] = useState(false);

  const burgerToggle = () => {
    setBurger(!burger);
  };

  const anchorClasses = "px-3 py-2 flex items-center leading-snug text-white";
  return (
    <div className="md:flex lg:flex-grow items-center mt-6 nav-main">
      <button onClick={burgerToggle} className={`p-2 flex-auto burger`}>
        {burger ? (
          <>
            <span className="closed"></span>
            <span className="closed"></span>
            <span className="closed"></span>
          </>
        ) : (
          <>
            <span></span>
            <span></span>
            <span></span>
          </>
        )}
      </button>
      <ul
        className={`flex flex-col lg:flex-row list-none ml-auto nav ${
          burger ? "show-menu" : ""
        }`}
      >
        <li>
          <Anchor href="/about" classes={anchorClasses}>
            About
          </Anchor>
        </li>
        <li>
          <Anchor href="/rockets" classes={anchorClasses}>
            Rockets
          </Anchor>
        </li>
        <li>
          <Anchor href="/launches" classes={anchorClasses}>
            Launches
          </Anchor>
        </li>
        <li>
          <Anchor href="/ships" classes={anchorClasses}>
            Ships
          </Anchor>
        </li>
      </ul>
    </div>
  );
};

export default NavMain;
