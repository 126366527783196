import React, { useEffect } from "react";
import routes from "./config/routes";
import Header from "./components/components/header/Header";
import Footer from "./components/components/footer/Footer";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import logging from "./config/logging";
import Loader from "./components/components/loader/Loader";
import { connect } from "react-redux";
import IReduxState from "./interfaces/store/reduxState";
import IAppPage from "./interfaces/pages/IAppPage";

/**
 * Main app component
 *
 * Sets up the router for all users
 * @constructor
 */
const App: React.FC<IAppPage> = ({ loading }) => {
  useEffect(() => {
    logging.info(`Loading application`);
  });

  return (
    <div className="App">
      {loading?.isLoading ? <Loader /> : null}
      <Header />

      <Switch>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props: RouteComponentProps<any>) => (
                <route.component
                  name={route.name}
                  {...props}
                  {...route.props}
                />
              )}
            />
          );
        })}
      </Switch>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state: IReduxState) => {
  return {
    loading: state?.loading,
  };
};

export default connect(mapStateToProps)(App);
