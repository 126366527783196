/**
 * Default configuration for app-wide use
 */

const config = {
    defaults: {
        namespace: 'spacex'
    }
}

export default config;