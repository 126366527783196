import React, { useEffect, useState } from "react";
import IPage from "../../../interfaces/page";
import { RouteComponentProps } from "react-router-dom";
import { undoUrlSafe } from "../../../helperFunctions/helper-functions";
import spaceXQuery from "../../../api/spacex";

const ShipPage: React.FC<IPage & RouteComponentProps<any>> = (props) => {
  const pathname = window.location.pathname;
  const shipName = pathname.split("/")[2];

  const [ship, setShip] = useState<any>({});

  useEffect(() => {
    // If no shipName, don't do anything
    if (!shipName) {
      return;
    }

    // Generate options array for spaceX API call
    const options = {
      endpoint: "ships",
      query: {
        name: {
          $eq: undoUrlSafe(shipName),
        },
      },
      options: {
        select: {
          active: 1,
          home_port: 1,
          id: 1,
          image: 1,
          link: 1,
          name: 1,
          type: 1,
          year_built: 1,
        },
      },
    };

    const getData = async () => {
      const shipData = await spaceXQuery(options);
      if (shipData) setShip(shipData?.docs[0]);
    };

    getData().then();
  }, [props, shipName]);

  return (
    <div className="p-4">
      <h1 data-testid="page-title" className="text-2xl mb-4 text-secondary">
        Ship{ship?.name ? `: ${ship.name}` : null}
      </h1>

      <table className="table-fixed">
        <thead>
          <tr>
            <th>Image</th>
            <th>Home Port</th>
            <th>Type</th>
            <th>Year Built</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-1/5 px-2 text-center">
              {ship?.image ? (
                <img src={ship?.image} alt={ship?.name} />
              ) : (
                <p>No image provided</p>
              )}
            </td>
            <td className="w-1/5 px-2 text-center">{ship?.home_port}</td>
            <td className="w-1/5 px-2 text-center">{ship?.type}</td>
            <td className="w-1/5 px-2 text-center">{ship?.year_built}</td>
            <td className="w-1/5 px-2 text-center">
              {ship?.link ? (
                <a href={ship?.link} target="_blank" rel="noreferrer">
                  More Info
                </a>
              ) : (
                <p>No Link</p>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ShipPage;
