import IShip from "../../../interfaces/components/ship";
import Anchor from "../../fields/anchor/Anchor";
import { createUrlSafe } from "../../../helperFunctions/helper-functions";

const ShipListItem = (ship: IShip) => {
  return (
    <tr key={ship.ship.index}>
      <td className="w-1/3 px-1 py-2 text-center">
        <Anchor href={`/ships/${createUrlSafe(ship.ship.name)}`}>
          {ship?.ship?.image ? (
            <img src={ship.ship.image} alt={ship.ship.name} />
          ) : (
            <p>No image provided</p>
          )}
        </Anchor>
      </td>
      <td className="w-1/3 px-1 text-center">
        <Anchor href={`/ship/${createUrlSafe(ship.ship.name)}`}>
          {ship.ship.name}
        </Anchor>
      </td>
      <td className="w-1/3 px-1 text-center">{ship.ship.year_built}</td>
    </tr>
  );
};

export default ShipListItem;
