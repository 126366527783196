import { useEffect } from "react";
import { userLogout } from "../../../store/action-creators/user";
import { useDispatch } from "react-redux";
import Anchor from "../../fields/anchor/Anchor";

const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userLogout());
  }, [dispatch]);

  return (
    <div className="p-4">
      <h2 className="text-2xl text-secondary">Logout</h2>
      <p>
        You have been successfully loggged out.{" "}
        <Anchor href="/login">Login again.</Anchor>
      </p>
    </div>
  );
};

export default LogoutPage;
