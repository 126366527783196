import React from "react";
import ILaunchListItem from "../../../interfaces/components/launch";
import Anchor from "../../fields/anchor/Anchor";
import {
  createUrlSafe,
  getDateOutput,
} from "../../../helperFunctions/helper-functions";

const LaunchListItem: React.FC<ILaunchListItem> = (launch: any) => {
  const date = new Date(launch.launch.date_utc);

  return (
    <>
      <tr key={launch.index}>
        <td className="w-1/4 px-1 text-center py-2">
          <Anchor href={`/launch/${createUrlSafe(launch.launch.name)}`}>
            {launch.launch.name}
          </Anchor>
        </td>
        <td className="w-1/4 px-1 text-center">
          {date ? getDateOutput(date) : null}
        </td>
        <td className="w-1/4 px-1 text-center">
          {launch.launch.flight_number}
        </td>
      </tr>
    </>
  );
};

export default LaunchListItem;
