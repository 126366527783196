import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ILogin from "../../../interfaces/components/login";
import { userLogin } from "../../../store/thunks";
import IReduxState from "../../../interfaces/store/reduxState";
import IUserLoginError from "../../../interfaces/errors-warnings/userLoginError";
import Alert from "../alert/Alert";
import IRocketItem from "../../../interfaces/components/rocketItem";
import spaceXQuery from "../../../api/spacex";

const Login: React.FC<ILogin> = (props) => {
  const [firstName, setFirstName] = useState<string>("");
  const [favoriteRocket, setFavoriteRocket] = useState<string>("");
  const [error, setError] = useState<IUserLoginError>({});
  const [allRockets, setAllRockets] = useState<any>([]);

  const handleLogin = async () => {
    // Clear errors when props change
    let newError = {};

    // If either field is missing, set error and return
    if (!firstName || !favoriteRocket) {
      newError = {
        active: true,
        message: "Please enter your name and select a favorite shipListItem",
      };
    }

    setError(newError);

    // Build data object to be passed to userLogin
    const data = {
      firstName: firstName,
      favoriteRocket: favoriteRocket,
    };

    // If userLogin is present, call the action creator to log the user in
    if (props.userLogin) {
      props.userLogin(data);
    }

    // If there is an error, display it
    if (props.error) {
      setError(props.error);
    }
  };

  useEffect(() => {
    if (props.error) {
      setError(props.error);
    }
  }, [props]);

  // Split this to a second useEffect because this doesn't need to fire every time props are updated.
  useEffect(() => {
    // Prepare for cleanup
    let isMounted = true;
    const retrieveRockets = async () => {
      const options = {
        endpoint: "rockets",
        query: {},
        options: {
          sort: { name: "asc" },
          select: {
            name: 1,
          },
        },
      };

      // Get Rocket List
      const list = await spaceXQuery(options);
      if (list && isMounted) {
        setAllRockets(list.docs);
        setFavoriteRocket(list.docs[0].name);
      }
    };

    retrieveRockets().then();

    // Cleanup useEFfect so it won't run after unmounting
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="login-component">
      <h2 className="text-secondary text-2xl">Register &amp; Login</h2>
      <p className="py-4 uppercase text-sm">
        This isn't a real login. Just enter a First Name and select a ship.
      </p>

      {error ? <Alert type="error" message={error.message} /> : null}
      <div className="flex">
        <div className="md:flex-1">
          <label htmlFor="firstName">FirstName</label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            onChange={(e) => setFirstName(e.target.value)}
            data-testid="first-name-input"
            className="mx-2"
          />
        </div>
        <div className="md:flex-1">
          <label htmlFor="firstName">Favorite Ship</label>
          <select
            onChange={(e) => setFavoriteRocket(e.target.value)}
            data-testid="shipListItem-select"
            className="mx-2"
          >
            {allRockets.map((singleRocket: IRocketItem, index: number) => {
              return (
                <option value={singleRocket.name} key={index}>
                  {singleRocket.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <button
        type="button"
        onClick={handleLogin}
        data-testid="login-submit"
        className="block m-auto bg-tertiary p-2 my-4"
      >
        Login
      </button>
    </div>
  );
};

const mapStateToProps = (state: IReduxState) => {
  return {
    error: state.auth.loginError,
  };
};

const mapDispatchToProps = {
  userLogin,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
