import React from "react";
import NavMain from "../navMain/NavMain";
import NavSub from "../navSub/NavSub";
import Logo from "../logo/Logo";
import ScrollToTop from "../scrollToTop/ScrollToTop";

const Header = () => {
  return (
    <div className="double-border-light md:flex p-4 mb-6 stars">
      <ScrollToTop />
      <div className="sm:text-center md:flex-initial md:flex-grow">
        <Logo />
      </div>
      <div className="menus text-center md:flex-initial md:flex-grow md:text-right">
        <NavSub />
        <NavMain />
      </div>
    </div>
  );
};

export default Header;
