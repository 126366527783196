const Loader = () => {
  return (
    <div className="theloader bg-background bg-opacity-70 absolute w-full h-full bg-white opacity-98 top-0 left-0 z-40">
      <div className="items-center mt-8 border-2 w-48 px-4 py-2 mx-auto my-0 bg-white">
        <div className="loader z-50"></div>
        <p className="text-secondary font-bold">Please wait. Loading...</p>
      </div>
    </div>
  );
};
export default Loader;
