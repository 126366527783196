import React from "react";
import IPage from "../../../interfaces/page";
import { RouteComponentProps } from "react-router-dom";
import Ships from "../../components/ships/Ships";

const ShipsPage: React.FC<IPage & RouteComponentProps<any>> = (props) => {
  return (
    <div className="p-4">
      <h2 data-testid="page-title" className="text-2xl text-secondary">
        Ships
      </h2>
      <p className="mt-2 mb-12">
        These are some honkin' ships here folks. It's easy to overlook the
        impact of these sea dwellers. Once, the most technologically advanced
        form of travel available, these behemoths are still instrumental to the
        modern world. Take a moment and appreciate the impact these ships have
        on landing reusable crafts.
      </p>
      <Ships />
    </div>
  );
};

export default ShipsPage;
