import React from "react";
import IPage from "../../../interfaces/page";
import { RouteComponentProps } from "react-router-dom";
import Launches from "../../components/launches/Launches";

const LaunchesPage: React.FC<IPage & RouteComponentProps<any>> = (props) => {
  return (
    <div className="p-4">
      <h2 data-testid="page-title" className="text-2xl text-secondary">
        Launches
      </h2>
      <p className="mt-2 mb-12">
        Each launch is a miracle of science and technology and should be
        celebrated accordingly. Find out more information about each launch
        below.
      </p>
      <Launches />
    </div>
  );
};

export default LaunchesPage;
