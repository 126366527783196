import IRocket from "../../../interfaces/components/rocketListItem";
import Anchor from "../../fields/anchor/Anchor";
import { createUrlSafe } from "../../../helperFunctions/helper-functions";

const RocketListItem = (rocket: IRocket) => {
  return (
    <tr key={rocket.rocket.index}>
      <td className="w-1/3 px-1 py-2 text-center">
        <Anchor href={`/rocket/${createUrlSafe(rocket.rocket.name)}`}>
          <img src={rocket.rocket.flickr_images[0]} alt={rocket.rocket.name} />
        </Anchor>
      </td>
      <td className="w-1/3 px-1 text-center">
        <Anchor href={`/rocket/${createUrlSafe(rocket.rocket.name)}`}>
          {rocket.rocket.name}
        </Anchor>
      </td>
      <td className="w-1/3 px-1 text-center">{`${rocket.rocket.height.feet}/${rocket.rocket.height.meters}`}</td>
    </tr>
  );
};

export default RocketListItem;
