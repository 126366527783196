import React from "react";
import IPage from "../../../interfaces/page";
import { RouteComponentProps } from "react-router-dom";
import Rockets from "../../components/rockets/Rockets";

const RocketsPage: React.FC<IPage & RouteComponentProps<any>> = (props) => {
  return (
    <div className="p-4">
      <h2 data-testid="page-title" className="text-2xl text-secondary">
        Rockets
      </h2>
      <p className="mt-2 mb-12">
        This is what we all came to see. The pinnacle of modern technology and
        an amazing example of the ingenuity of man. These rockets have some of
        the worlds most cutting edge technologies driving them and enabling
        research, the likes of which, have never been realized before.{" "}
      </p>
      <Rockets />
    </div>
  );
};

export default RocketsPage;
