import React, { useState } from "react";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import ISlider from "../../../interfaces/components/slider";

const Slider: React.FC<ISlider> = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  console.log("zzzCurrentSlide", slides);
  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
  };

  if (!slides) {
    return <p>No images found.</p>;
  }

  return (
    <div className="slider relative">
      <FaArrowAltCircleRight className="arrow-right icon" onClick={nextSlide} />
      <FaArrowAltCircleLeft className="arrow-left icon" onClick={prevSlide} />

      {slides &&
        slides.map((slide: any, index: number) => {
          const currentSlideClass = index === currentSlide ? "active" : "";

          return (
            <div className={`slide ${currentSlideClass}`} key={index}>
              {index === currentSlide && (
                <img src={slide} alt="SpaceX Api - No Alt found" />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default Slider;
