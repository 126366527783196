import { useEffect, useState } from "react";
import IProfile from "../../../interfaces/components/profile";
import Anchor from "../../fields/anchor/Anchor";
import Rocket from "../rocket/Rocket";
import spaceXQuery from "../../../api/spacex";

const Profile: React.FC<IProfile> = ({ user }) => {
  const [rocket, setRocket] = useState();

  useEffect(() => {
    const getShipInfo = async () => {
      if (user?.favoriteRocket) {
        // Generate options array for spaceX API call
        const options = {
          endpoint: "rockets",
          query: {
            name: {
              $eq: user.favoriteRocket,
            },
          },
          options: {
            select: {
              id: 1,
              first_flight: 1,
              flickr_images: 1,
              height: 1,
              name: 1,
            },
          },
        };

        const rocketResponse = await spaceXQuery(options);

        if (rocketResponse?.docs) setRocket(rocketResponse.docs[0]);
      }
    };

    getShipInfo().then();
  }, [user]);

  return (
    <>
      {user && Object.keys(user).length ? (
        <>
          <p>
            <strong>UserName:</strong> {user?.firstName}
          </p>
          <p>
            <strong>Favorite Ship:</strong> {user?.favoriteRocket}
          </p>
          {rocket ? <Rocket rocket={rocket} /> : null}
        </>
      ) : (
        <p>
          You are not currently logged in. Please{" "}
          <Anchor href="/login">Login</Anchor> to view your profile.
        </p>
      )}
    </>
  );
};

export default Profile;
