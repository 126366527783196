export enum ActionTypeUser {
  USER_LOGIN = "userLogin",
  USER_LOGOUT = "userLogout",
  USER_UPDATE = "userUpdate",
  USER_LOGIN_CLEAR_ERROR = "userLoginClearError",
  USER_LOGIN_FAILURE = "userLoginFailure",
}

export enum ActionTypeLoading {
  LOADING_ACTIVE = "loadingActive",
  LOADING_INACTIVE = "loadingInactive",
}
