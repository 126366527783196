/**
 * Action Creators - Users
 */

// Functions that dispatch actions

import { ActionTypeUser } from "../action-types";
import { Dispatch } from "redux";
import { ActionUser } from "../actions/user";

export const userLogout = () => {
  return (dispatch: Dispatch<ActionUser>) => {
    dispatch({
      type: ActionTypeUser.USER_LOGOUT,
    });
  };
};
export const userUpdate = (userObject: object) => {
  return (dispatch: Dispatch<ActionUser>) => {
    dispatch({
      type: ActionTypeUser.USER_UPDATE,
      payload: userObject,
    });
  };
};

export const userLoginClear = () => {
  return (dispatch: Dispatch<ActionUser>) => {
    dispatch({
      type: ActionTypeUser.USER_LOGIN_CLEAR_ERROR,
    });
  };
};
