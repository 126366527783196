import React from "react";
import IRocket from "../../../interfaces/components/rocket";

const Rocket: React.FC<IRocket> = (rocket) => {
  return (
    <div className="p-4">
      <h1 data-testid="page-title" className="text-2xl mb-4">
        Favorite Rocket
        {rocket?.rocket?.name ? `: ${rocket?.rocket?.name}` : null}
      </h1>

      <table className="table-fixed">
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>FirstFlight</th>
            <th>Height (ft/m)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-1/5 px-2 text-center">
              <img
                src={
                  rocket?.rocket?.flickr_images
                    ? rocket?.rocket?.flickr_images[0]
                    : "#"
                }
                alt={rocket?.rocket?.name}
              />
            </td>
            <td className="w-1/5 px-2 text-center">{rocket?.rocket?.name}</td>
            <td className="w-1/5 px-2 text-center">
              {rocket?.rocket?.first_flight}
            </td>
            <td className="w-1/5 px-2 text-center">{`${rocket?.rocket?.height?.feet}/${rocket?.rocket?.height?.meters}`}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Rocket;
