import { combineReducers } from "redux";
import authReducer from "./authReducer";
import loadingReducer from "./loadingReducer";

// Combine all reducers for the store
// Theres only one but most projects will have multiple
const combinedReducers = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
});

export default combinedReducers;
