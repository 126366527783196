import config from "./config";
import { getDate } from "../helperFunctions/helper-functions";

const DEFAULT_NAMESPACE = config.defaults.namespace;

/**
 * Log an info message to log
 *
 * @param message
 * @param namespace
 */
const info = (message: any, namespace?: string) => {
  if (typeof message === "string") {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [INFO] ${message}`
    );
  } else {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [INFO] ${message}`
    );
  }
};

/**
 * Log an info message to log
 *
 * @param message
 * @param namespace
 */
const warn = (message: any, namespace?: string) => {
  if (typeof message === "string") {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [WARN] ${message}`
    );
  } else {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [WARN] ${message}`
    );
  }
};

/**
 * Log an info message to log
 *
 * @param message
 * @param namespace
 */
const error = (message: any, namespace?: string) => {
  if (typeof message === "string") {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [WARN] ${message}`
    );
  } else {
    console.log(
      `[${getDate()}] [${namespace || DEFAULT_NAMESPACE}] [ERROR] ${message}`
    );
  }
};

/**
 * Set up callable export function
 */
const logging = {
  info,
  warn,
  error,
};
export default logging;
