import React from "react";
import IPage from "../../../interfaces/page";
import { RouteComponentProps } from "react-router-dom";

const AboutPage: React.FC<IPage & RouteComponentProps<any>> = (props) => {
  return (
    <div className="p-4 about">
      <h2 data-testid="page-title" className="text-2xl text-secondary">
        About
      </h2>
      <div>
        <p className="my-1">
          Welcome to my SpaceX application! This is a portfolio piece intended
          to showcase my React coding abilities. This site has many features
          that can be found on commercial and government websites. Some of these
          features are, probably, overkill for such a simple app. To that end,
          I've left comments throughout the source code explaining why I made
          certain decisions. Mostly the reason is "if this were a bigger
          project, I'd do it like this".
        </p>
        <p className="my-1">
          Check out the source code on{" "}
          <a
            href="https://gitlab.com/andrewadcock/spacex-api"
            target="_blank"
            rel="noreferrer"
          >
            Gitlab
          </a>
          .
        </p>
        <h3 className="my-4">Some things to check out on this site: </h3>
        <ul>
          <li>Using Redux for state management</li>
          <li>Using local storage for redux state storage</li>
          <li>Use of thunks, middleware, and newer tools (redux toolkit)</li>
          <li>User Authentication system - ultra simple example</li>
          <li>Simulated time for DB, IdP, API calls</li>
          <li>Loading Popup for longer calls</li>
          <li>Use of Tailwind CSS</li>
          <li>Use of Typescript</li>
          <li>Clean directory structure and separation of concerns</li>
          <li>Async/Await</li>
          <li>
            Axios - Not many calls? Well, they are all pretty much the same
          </li>
        </ul>
      </div>

      <h3 className="my-4">Reflections</h3>
      <dl>
        <dt className="font-bold mt-3 mb-1">Tailwind CSS:</dt>
        <dd>
          Tailwind CSS is a great tools that provides a standardized way of
          abstracting CSS into classes in lieu of a standard css file. This is
          super great for simple sites, however, design heavy and large sites
          don't realize the benefits as much. Given the design of this site, I
          think SASS/SCSS would be a better fix. The patterns could be mixins
          which would be adventageous given how similar they are. Instead of
          using Tailwind classes, I fell back to classic css. Some of this just
          isn't possible with Tailwind.{" "}
        </dd>
        <dt className="font-bold mt-3 mb-1">TypeScript</dt>
        <dd>
          This was my first project using TypeScript. In the past, I have used
          the{" "}
          <a
            href="https://www.npmjs.com/package/prop-types"
            target="_blank"
            rel="noreferrer"
          >
            Prop-Types npm package
          </a>{" "}
          on a few applications and thought it was time to take the next step.
          I'm not 100% thrilled with my organization of interfaces. Initially, I
          thought to keep them in the corresponding files, however, as soon as I
          need to reuse one, that became a less desireable method. Instead, I,
          lightly, mimicked the components and store directory structure for
          organization. This could be improved still and I'm working on a better
          solution.
        </dd>
      </dl>
    </div>
  );
};

export default AboutPage;
