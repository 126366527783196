import axios from "axios";

const apiUrl = "https://api.spacexdata.com/v4/";

const spaceXQuery = async (options: any) => {
  const endpoint = options.endpoint;
  const limit = options.options.limit ?? 10;
  const queryOffset =
    options.options.offset > 0 ? (options.options.offset - 1) * limit : 0;
  const sort = options.options.sort ?? {};
  const query = options.query ?? {};
  const select = options.options.select ?? {};

  let items: any = [];

  await axios
    .post(`${apiUrl}${endpoint}/query`, {
      query: query,
      options: {
        offset: queryOffset,
        select: select,
        sort: sort,
        limit: limit,
      },
    })
    .then((response) => {
      items = response?.data;
    })
    .catch((err) => {
      console.log("Error retrieving SpaceX data", err);
    });

  return items;
};

export default spaceXQuery;
