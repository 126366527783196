import React, { useEffect, useState } from "react";
import IPage from "../../../interfaces/page";
import { connect } from "react-redux";
import IReduxState from "../../../interfaces/store/reduxState";
import ICurrentUser from "../../../interfaces/store/currentUser";
import Anchor from "../../fields/anchor/Anchor";
import Launches from "../../components/launches/Launches";
import Ships from "../../components/ships/Ships";
import Login from "../../components/login/Login";
import Rockets from "../../components/rockets/Rockets";
import Profile from "../../components/profile/Profile";

const HomePage: React.FC<IPage> = (props) => {
  const [user, setUser] = useState<ICurrentUser>({});

  useEffect(() => {
    if (props?.auth?.currentUser) {
      setUser(props.auth.currentUser);
    }
  }, [props]);

  return (
    <div className="p-4">
      <div className="mb-16 md:mb-8 md:flex py-4 pb-12 double-border-light sm:text-center md:text-left relative">
        <div className="md:flex-1 md:pr-12 sm:pb-12 z-50 relative">
          <h2 className="text-2xl text-secondary mb-2">
            Welcome to my SpaceX API demo site
            {user?.firstName ? `, ${user.firstName}` : null}!
          </h2>
          <p>
            This is small application displays some interesting facts about
            SpaceX and the machinery used. The real goal of this project is to
            showcase my JavaScript abilities. Please visit the{" "}
            <Anchor href="/about">About Page</Anchor> for more information on
            the tools used and how, and why, things were implemented.
          </p>
        </div>
        <div className="my-12 md:my-2 md:flex-1  sm:text-center z-50 relative ">
          {!user?.firstName ? <Login /> : <Profile user={user} />}
        </div>
      </div>
      <div className="md:flex">
        <div className="md:flex-1">
          <Rockets />
        </div>
        <div className="md:flex-1">
          <Launches />
        </div>
        <div className="md:flex-1">
          <Ships />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IReduxState) => {
  return {
    auth: state?.auth,
  };
};

export default connect(mapStateToProps)(HomePage);
