/**
 * This file contains general functions for use throughout the application.
 */

/**
 * Returns date in ISO format
 */
const getDate = () => {
  return new Date().toISOString();
};

/**
 * Returns formatted date
 */
const getDateOutput = (date: Date) => {
  return `${date.getMonth()}/${date.getDate()}/${date
    .getFullYear()
    .toString()
    .substr(-2)}`;
};

/**
 * Sets up a three second delay
 *
 * This is just to simulate some functionality taking a while around the site.
 */
const slowResponse = async () => {
  await new Promise((resolve) => setTimeout(resolve, 3000));
};

const createUrlSafe = (string: string) => {
  return string.replace(/\s+/g, "+").replace("/", "@");
};

const undoUrlSafe = (string: string) => {
  return string.replace(/\+/g, " ").replace(/@/g, "/");
};

export { getDate, getDateOutput, slowResponse, createUrlSafe, undoUrlSafe };
