import { useEffect, useState } from "react";
import LaunchListItem from "../launchListItem/LaunchListItem";
import Anchor from "../../fields/anchor/Anchor";
import spaceXQuery from "../../../api/spacex";

const Launches = () => {
  const pathname = window.location.pathname;
  const queryOffset = parseInt(pathname.split("/")[2]);

  const [launches, setLaunches] = useState<any>();
  const [nav, setNav] = useState<any>({
    hasNextPage: false,
    hasPrevPage: false,
  });

  useEffect(() => {
    const options = {
      endpoint: "launches",
      query: {
        upcoming: {
          $eq: true,
        },
      },
      options: {
        limit: 10,
        sort: { name: "asc" },
        offset: queryOffset,
        select: {
          date_utc: 1,
          flight_number: 1,
          id: 1,
          name: 1,
          ships: 1,
        },
      },
    };

    const getData = async () => {
      const data = await spaceXQuery(options);
      setLaunches(data.docs);
      setNav({
        hasNextPage: data.hasNextPage,
        hasPrevPage: data.hasPrevPage,
        prevPage: data.prevPage ?? 0,
        nextPage: data.nextPage ?? 0,
      });
    };
    getData().then();
  }, [queryOffset]);
  return (
    <div className="text-primary-dark box-glow py-4 px-6 m-6">
      <h3 className="text-xl border-b-2 border-secondary mb-2 text-secondary">
        Launches
      </h3>

      <p className="pb-6 uppercase text-sm">
        The last {launches ? launches.length : "x"} Launches
      </p>
      {launches ? (
        <table className="table-fixed">
          <thead>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Flight No.</th>
            </tr>
          </thead>
          <tbody>
            {launches.map((launch: any, index: number) => {
              // Should this be it's own component? For this example, probably not. If this were larger, I'd recommend
              // this approach to make testing easier but the trade off with a table split between components, a little
              // weird.
              const launchWithIndex = {
                ...launch,
                index,
              };
              return <LaunchListItem launch={launchWithIndex} key={index} />;
            })}
          </tbody>
        </table>
      ) : (
        <p>Launches loading...</p>
      )}
      <div className="flex">
        <div className="flex-1">
          {nav.hasPrevPage ? (
            <Anchor href={`/launches/${nav.prevPage}`}>Previous</Anchor>
          ) : null}
        </div>

        <div className="flex-1 text-right">
          {nav.hasNextPage ? (
            <Anchor href={`/launches/${nav.nextPage}`}>Next</Anchor>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Launches;
