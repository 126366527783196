import { useEffect, useState } from "react";
import Anchor from "../../fields/anchor/Anchor";
import RocketListItem from "../rocketListItem/RocketListItem";
import spaceXQuery from "../../../api/spacex";

const Rockets = () => {
  const pathname = window.location.pathname;
  const queryOffset = parseInt(pathname.split("/")[2]);
  const [rockets, setRockets] = useState<any>();
  const [nav, setNav] = useState<any>({
    hasNextPage: false,
    hasPrevPage: false,
  });

  useEffect(() => {
    // Generate options array for spaceX API call
    const options = {
      endpoint: "rockets",
      query: {},
      options: {
        limit: 10,
        sort: { name: "asc" },
        offset: queryOffset,
        select: {
          id: 1,
          flickr_images: 1,
          height: 1,
          name: 1,
        },
      },
    };

    // Call action creator to get a list of launches for the table below
    const getData = async () => {
      const data = await spaceXQuery(options);

      setRockets(data.docs);
      setNav({
        hasNextPage: data.hasNextPage,
        hasPrevPage: data.hasPrevPage,
        prevPage: data.prevPage ?? 0,
        nextPage: data.nextPage ?? 0,
      });
    };

    getData().then();
  }, [queryOffset]);

  return (
    <div className="box-glow py-4 px-6 m-6">
      <h3 className="text-xl border-b-2 border-secondary text-secondary mb-2">
        Rockets
      </h3>
      <p className="pb-6 uppercase text-sm">
        {rockets ? rockets.length : null} Rockets, Alphabetically
      </p>
      {rockets ? (
        <table className="table-fixed">
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Height (ft/m)</th>
            </tr>
          </thead>
          <tbody>
            {rockets.map((rocket: any, index: number) => {
              const rocketWithIndex = {
                ...rocket,
                index,
              };
              return <RocketListItem rocket={rocketWithIndex} key={index} />;
            })}
          </tbody>
        </table>
      ) : (
        <p>Rockets loading...</p>
      )}
      <div className="flex">
        <div className="flex-1">
          {nav.hasPrevPage ? (
            <Anchor href={`/rockets/${nav.prevPage}`}>Previous</Anchor>
          ) : null}
        </div>

        <div className="flex-1 text-right">
          {nav.hasNextPage ? (
            <Anchor href={`/rockets/${nav.nextPage}`}>Next</Anchor>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Rockets;
