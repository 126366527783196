import React, { useEffect } from "react";
import IPage from "../../../interfaces/page";
import { RouteComponentProps } from "react-router-dom";
import Login from "../../components/login/Login";
import IReduxState from "../../../interfaces/store/reduxState";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const LoginPage: React.FC<IPage & RouteComponentProps<any>> = (props) => {
  const history = useHistory();

  useEffect(() => {
    // If user is logged in, redirect to profile page
    if (props?.auth?.isLoggedIn) {
      history.push("/profile");
    }
  }, [props, history]);

  return (
    <div className="p-4">
      <h2 data-testid="page-title" className="text-2xl text-secondary">
        Login
      </h2>
      <Login />
    </div>
  );
};

const mapStateToProps = (state: IReduxState) => {
  return {
    auth: state?.auth,
  };
};

export default connect(mapStateToProps)(LoginPage);
