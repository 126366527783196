import IAlert from "../../../interfaces/components/alert";

const Alert: React.FC<IAlert> = ({ type, message }) => {
  let typeClasses;
  switch (type) {
    case "error":
      typeClasses = "bg-red-500";
      break;
    case "warning":
      typeClasses = "bg-yellow-500";
      break;
    default:
      typeClasses = "bg-green-500";
  }
  return (
    <div className={`bg-red-500 text-white ${typeClasses}`}>{message}</div>
  );
};

export default Alert;
