import React from "react";
import IAnchor from "../../../interfaces/fields/anchor";
import { Link } from "react-router-dom";

const Anchor: React.FC<IAnchor> = ({
  classes = "",
  href,
  target = "_self",
  children,
}) => {
  // Classes that apply to all anchors
  const staticClasses =
    "hover:opacity-75 text-primary-light underline hover:text-primary";

  return (
    <Link
      to={href ?? "#"}
      target={target}
      className={staticClasses + " " + classes}
    >
      {children}
    </Link>
  );
};

export default Anchor;
