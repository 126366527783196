import React, { useEffect, useState } from "react";
import IPage from "../../../interfaces/page";
import { RouteComponentProps } from "react-router-dom";
import { undoUrlSafe } from "../../../helperFunctions/helper-functions";
import spaceXQuery from "../../../api/spacex";
import Slider from "../../components/slider/Slider";

const RocketPage: React.FC<IPage & RouteComponentProps<any>> = (props) => {
  const pathname = window.location.pathname;
  const rocketName = pathname.split("/")[2];

  const [rocket, setRocket] = useState<any>({});

  useEffect(() => {
    // If no rocketId, don't do anything
    if (!rocketName) {
      return;
    }

    // Generate options array for spaceX API call
    const options = {
      endpoint: "rockets",
      query: {
        name: {
          $eq: undoUrlSafe(rocketName),
        },
      },
      options: {
        select: {
          id: 1,
          first_flight: 1,
          flickr_images: 1,
          height: 1,
          name: 1,
        },
      },
    };

    const getData = async () => {
      const rocketData = await spaceXQuery(options);
      if (rocketData) setRocket(rocketData.docs[0]);
    };

    getData().then();
  }, [props, rocketName]);

  return (
    <div className="p-4">
      <h1 data-testid="page-title" className="text-2xl mb-4 text-secondary">
        Rocket{rocket?.name ? `: ${rocket.name}` : null}
      </h1>

      <table className="table-fixed">
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>FirstFlight</th>
            <th>Height (ft/m)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-1/5 px-2 text-center">
              {/*<img*/}
              {/*  src={rocket?.flickr_images ? rocket?.flickr_images[0] : null}*/}
              {/*  alt={rocket?.name}*/}
              {/*/>*/}
              {rocket?.flickr_images ? (
                <Slider slides={rocket?.flickr_images} />
              ) : (
                <p>No images found.</p>
              )}
            </td>
            <td className="w-1/5 px-2 text-center">{rocket?.name}</td>
            <td className="w-1/5 px-2 text-center">{rocket?.first_flight}</td>
            <td className="w-1/5 px-2 text-center">{`${rocket?.height?.feet}/${rocket?.height?.meters}`}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RocketPage;
