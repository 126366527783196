/**
 * Thunks
 *
 * There is no real reason these couldn't be with the other action-creators but I'm attempting to simulate a larger
 * application which would have more methods. This is just one approach to organization. Splitting by type would be
 * another good choice.
 */

import { ActionTypeLoading, ActionTypeUser } from "../action-types";
import { ActionUser } from "../actions/user";
import IReduxState from "../../interfaces/store/reduxState";
import { slowResponse } from "../../helperFunctions/helper-functions";
import { Dispatch } from "redux";

/**
 * User Login Thunk
 *
 * Why is this a thunk?
 * In an actual application logging in would require hitting a database or IDP or something. So, to mess with this and
 * make it feel more real, there is a timeout for a successful login and a 20% chance of login failure.
 * @param userObject
 */
export const userLogin =
  (userObject: object) =>
  async (dispatch: Dispatch<ActionUser>, state: IReduxState) => {
    // Dispatch Loading Popup
    dispatch({
      type: ActionTypeLoading.LOADING_ACTIVE,
    });

    // Simulate a slow, database call
    await slowResponse();

    // Give a twenty percent chance for failure
    const randomNumber = Math.floor(Math.random() * 5);
    if (randomNumber === 1) {
      dispatch({
        type: ActionTypeUser.USER_LOGIN_FAILURE,
        payload:
          "Error: This is simulated to show off some error functionality. Login again. There is only a 20 percent chance of failure.",
      });
    } else {
      dispatch({
        type: ActionTypeUser.USER_LOGIN,
        payload: userObject,
      });
      dispatch({
        type: ActionTypeUser.USER_LOGIN_CLEAR_ERROR,
      });
    }

    // Remove Loader
    dispatch({
      type: ActionTypeLoading.LOADING_INACTIVE,
    });
  };
