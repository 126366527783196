import IRoute from "../interfaces/route";
import HomePage from "../components/pages/home/Home";
import AboutPage from "../components/pages/about/About";
import ShipPage from "../components/pages/ship/Ship";
import ShipsPage from "../components/pages/ships/Ships";
import LoginPage from "../components/pages/login/Login";
import ProfilePage from "../components/pages/profile/Profile";
import LogoutPage from "../components/pages/logout/Logout";
import LaunchesPage from "../components/pages/launces/Launches";
import LaunchPage from "../components/pages/launch/Launch";
import RocketsPage from "../components/pages/rockets/Rockets";
import RocketPage from "../components/pages/rocket/Rocket";

const routes: IRoute[] = [
  {
    path: "/",
    name: "Home page",
    component: HomePage,
    exact: true,
  },
  {
    path: "/about",
    name: "About page",
    component: AboutPage,
    exact: true,
  },
  {
    path: "/launches",
    name: "Launches page",
    component: LaunchesPage,
    exact: true,
  },
  {
    path: "/launches/:id",
    name: "Launch List page",
    component: LaunchesPage,
    exact: true,
  },
  {
    path: "/launch/:id",
    name: "Launch page",
    component: LaunchPage,
    exact: true,
  },

  {
    path: "/profile",
    name: "Profile page",
    component: ProfilePage,
    exact: true,
  },
  {
    path: "/login",
    name: "Login page",
    component: LoginPage,
    exact: true,
  },
  {
    path: "/logout",
    name: "Logout page",
    component: LogoutPage,
    exact: true,
  },
  {
    path: "/rockets",
    name: "Rockets page",
    component: RocketsPage,
    exact: true,
  },
  {
    path: "/rockets/:id",
    name: "Rockets List page",
    component: RocketsPage,
    exact: true,
  },
  {
    path: "/rocket/:id",
    name: "Rocket page",
    component: RocketPage,
    exact: true,
  },
  {
    path: "/ships",
    name: "Ship page",
    component: ShipsPage,
    exact: true,
  },
  {
    path: "/ships/:id",
    name: "Ships List page",
    component: ShipsPage,
    exact: true,
  },
  {
    path: "/ship/:id",
    name: "Ship page",
    component: ShipPage,
    exact: true,
  },
];

export default routes;
