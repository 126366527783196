import React from "react";
import Anchor from "../../fields/anchor/Anchor";

const Footer = () => {
  return (
    <div className="footer border-t-2 pb-6 mt-12 text-sm relative">
      <div className="grid-footer"></div>
      <div className="grid-footer-top"></div>
      <div className="grid-footer-gradient"></div>
      <div className="footer-content p-4 relative">
        <div className="md:flex">
          <div className="sm:text-center md:flex-initial md:flex-grow">
            <div
              className="text-1xl mt-8 text-center md:text-left"
              data-testid="page-title"
            >
              <Anchor href="/">SpaceX - API</Anchor>
            </div>
          </div>
          <div
            className="sm:text-center md:flex-initial md:flex-grow md:text-right"
            data-testid="gitlab"
          >
            <a
              href="https://gitlab.com/andrewadcock/spacex-api"
              target="_blank"
              className="mr-2"
              rel="noreferrer"
            >
              <span className="w-8 inline-block mt-4">
                <img
                  src="/images/gitlab-icon-rgb.svg"
                  alt="Project in Gitlab"
                  className="w-full"
                />
              </span>
              Project in GitLab
            </a>
            <span className="text-primary-light">|</span>
            <a
              href="https://andrewadcock.com"
              target="_blank"
              className="ml-2"
              data-testid="portfolio"
              rel="noreferrer"
            >
              https://AndrewAdcock.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
