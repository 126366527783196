import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IReduxState from "../../../interfaces/store/reduxState";
import IProfilePage from "../../../interfaces/pages/IProfilePage";
import { RouteComponentProps } from "react-router-dom";
import Profile from "../../components/profile/Profile";

const ProfilePage: React.FC<IProfilePage & RouteComponentProps<any>> = ({
  auth,
}) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    if (auth?.currentUser) {
      setUser(auth.currentUser);
    }
  }, [auth]);

  return (
    <div className="p-4">
      <h2 className="text-2xl text-secondary">My Profile</h2>
      <Profile user={user} />
    </div>
  );
};

const mapStateToProps = (state: IReduxState) => {
  return {
    auth: state?.auth,
  };
};
export default connect(mapStateToProps)(ProfilePage);
