import { applyMiddleware, createStore } from "redux";
import combinedReducers from "./reducers";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadState, saveState } from "./middleware/localStorage";
import { throttle } from "lodash";

// Pull state from local storage
const persistedState = loadState();

// Include thunk middleware
const composeEnhancers = composeWithDevTools(applyMiddleware(thunk));

// Create the store with all the reducers, add localStore state, and set up for thunks (middleware).
const store = createStore(combinedReducers, persistedState, composeEnhancers);

// Subscribe the store to save to local state
store.subscribe(
  throttle(() => {
    saveState(store.getState() ?? { auth: {}, type: "null", loading: {} });
  }, 1000)
);

export default store;
