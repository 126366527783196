import React, { useEffect, useState } from "react";
import IAuth from "../../../interfaces/auth";
import INavSub from "../../../interfaces/components/navSub";
import IReduxState from "../../../interfaces/store/reduxState";
import { connect } from "react-redux";
import Anchor from "../../fields/anchor/Anchor";

const NavSub: React.FC<INavSub> = (props) => {
  // Set up local state
  const [auth, setAuth] = useState<IAuth>({});
  const [showLoginRegister, setShowLoginRegister] = useState<boolean>(true);

  useEffect(() => {
    // Set auth from redux to local state
    if (props?.auth) {
      setAuth(props.auth);
    }

    // Get current path from url
    const location = window.location.pathname;

    // Set up array of pages that login should be hidden on
    const hiddenPages = ["/login"];

    // If current page is in the hiddenPages array, don't show login
    if (hiddenPages.includes(location)) {
      setShowLoginRegister(false);
    }
  }, [props.auth]);
  return (
    <div className="flex-initial">
      {showLoginRegister || auth.isLoggedIn ? (
        <div
          className="md:flex lg:flex-grow items-center"
          id="example-navbar-info"
        >
          <ul className="flex flex-col lg:flex-row list-none ml-auto">
            {auth.isLoggedIn ? (
              <>
                <li className="nav-item">
                  <Anchor
                    href="/profile"
                    classes="px-3 py-2 text-xs uppercase font-bold text-secondary"
                  >
                    Profile
                  </Anchor>
                </li>
                <li className="nav-item">
                  <Anchor
                    href="/logout"
                    classes="px-3 py-2 text-xs uppercase font-bold text-secondary"
                  >
                    Logout
                  </Anchor>
                </li>
              </>
            ) : (
              <li className="nav-item">
                <Anchor
                  href="/login"
                  classes="px-3 py-2 text-xs uppercase font-bold text-secondary"
                >
                  Register &amp; Login
                </Anchor>
              </li>
            )}
          </ul>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: IReduxState) => {
  return {
    auth: state?.auth,
  };
};

export default connect(mapStateToProps)(NavSub);
